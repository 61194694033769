<template>
  <div
    layout="column"
    class="ma-3"
  >
    <div class="title">
      Administration
    </div>

    <div
      layout="row"
      class="mt-3"
    >
      <v-list
        style="width: 300px; background: transparent; border-right: 1px solid #ddd"
      >
        <v-list-group
          no-action
          prepend-icon="mdi-clipboard-text"
          :value="
            [
              'admin-sessions-centers-index',
              'admin-sessions-centers-edit',
              'admin-sessions-topics-index',
              'admin-sessions-topics-edit',
              'admin-sessions-targets-index',
              'admin-sessions-topics-show',
              'admin-sessions-topics-show-info',
              'admin-sessions-topics-show-timeslots',
              'admin-sessions-topics-show-incompatibilities',
              'admin-sessions-topics-show-availabilities',
              'admin-sessions-targets-edit',
              'admin-sessions-settings-index',
              'admin-sessions-settings-edit',
              'admin-sessions-activities-index',
              'admin-sessions-activities-edit',
              'admin-sessions-seasons-index',
              'admin-sessions-seasons-edit',
              'admin-sessions-teams-index',
              'admin-sessions-teams-edit',
              'admin-sessions-packages-index',
              'admin-sessions-packages-edit',
              'admin-sessions-groupstypes-index',
              'admin-sessions-groupstypes-edit'
            ].includes(route)
          "
        >
          <v-list-tile slot="activator">
            <v-list-tile-content>
              <v-list-tile-title>Sessions</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile :to="{ name: 'admin-sessions-topics-index' }">
            <v-list-tile-content>
              <v-list-tile-title>Concepts</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile :to="{ name: 'admin-sessions-groupstypes-index' }">
            <v-list-tile-content>
              <v-list-tile-title>Types of groups</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile :to="{ name: 'admin-sessions-targets-index' }">
            <v-list-tile-content>
              <v-list-tile-title>Target groups</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile :to="{ name: 'admin-sessions-settings-index' }">
            <v-list-tile-content>
              <v-list-tile-title>Events settings</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile :to="{ name: 'admin-sessions-activities-index' }">
            <v-list-tile-content>
              <v-list-tile-title>Events activities</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile
            v-if="isFreelances"
            :to="{ name: 'admin-sessions-centers-index' }"
          >
            <v-list-tile-content>
              <v-list-tile-title>Centers</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile
            v-if="isFreelances"
            :to="{ name: 'admin-sessions-teams-index' }"
          >
            <v-list-tile-content>
              <v-list-tile-title>Teams</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile
            v-if="isFreelances"
            :to="{ name: 'admin-sessions-seasons-index' }"
          >
            <v-list-tile-content>
              <v-list-tile-title>Seasons</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile
            v-if="isFreelances"
            :to="{ name: 'admin-sessions-packages-index' }"
          >
            <v-list-tile-content>
              <v-list-tile-title>Packages</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list-group>
        <v-list-group
          no-action
          prepend-icon="mdi-clipboard-account"
          :value="
            [
              'admin-addressbook-schooltypes-index',
              'admin-addressbook-schooltypes-edit',
              'admin-addressbook-functions-index',
              'admin-addressbook-functions-edit'
            ].includes(route)
          "
        >
          <v-list-tile slot="activator">
            <v-list-tile-content>
              <v-list-tile-title>Address book</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile :to="{ name: 'admin-addressbook-schooltypes-index' }">
            <v-list-tile-content>
              <v-list-tile-title>School types</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile :to="{ name: 'admin-addressbook-functions-index' }">
            <v-list-tile-content>
              <v-list-tile-title>Functions of contacts</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list-group>
        <v-list-group
          no-action
          prepend-icon="mdi-calendar"
          :value="
            [
              'admin-planning-periodtypes-index',
              'admin-planning-periodtypes-edit',
              'admin-planning-periods-index',
              'admin-planning-periods-edit'
            ].includes(route)
          "
        >
          <v-list-tile slot="activator">
            <v-list-tile-content>
              <v-list-tile-title>Planning</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile :to="{ name: 'admin-planning-periodtypes-index' }">
            <v-list-tile-content>
              <v-list-tile-title>Period types</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile :to="{ name: 'admin-planning-periods-index' }">
            <v-list-tile-content>
              <v-list-tile-title>Periods</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list-group>
        <v-list-group
          no-action
          prepend-icon="mdi-form-dropdown"
          :value="
            [
              'admin-forms-index',
              'admin-forms-edit',
              'admin-forms-fields-index',
              'admin-forms-bstypes-index',
              'admin-forms-bstypes-edit'
            ].includes(route)
          "
        >
          <v-list-tile slot="activator">
            <v-list-tile-content>
              <v-list-tile-title>Forms</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile :to="{ name: 'admin-forms-index' }">
            <v-list-tile-content>
              <v-list-tile-title>Forms' list</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile v-if="isBeesecure" :to="{ name: 'admin-forms-bstypes-index' }">
            <v-list-tile-content>
              <v-list-tile-title>BEE SECURE's forms</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list-group>
        <v-list-group
          no-action
          prepend-icon="mdi-cog-box"
          :value="
            [
              'admin-settings-groups-index',
              'admin-settings-groups-edit',
              'admin-settings-users-index',
              'admin-settings-users-list',
              'admin-settings-users-profiles-matrix',
              'admin-settings-users-contracts',
              'admin-settings-users-show',
              'admin-settings-users-show-info',
              'admin-settings-users-show-historic',
              'admin-settings-users-show-contracts',
              'admin-settings-users-show-skills',
              'admin-settings-users-show-skills-edit',
              'admin-settings-users-show-skills-seasons',
              'admin-settings-users-edit',
              'admin-settings-values-index'
            ].includes(route)
          "
        >
          <v-list-tile slot="activator">
            <v-list-tile-content>
              <v-list-tile-title>Settings</v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action v-if="waitingUsers">
              <v-chip
                color="red"
                text-color="white"
                small
              >
                {{ waitingUsers }}
              </v-chip>
            </v-list-tile-action>
          </v-list-tile>

          <v-list-tile
            v-if="isAdmin"
            :to="{ name: 'admin-settings-groups-index' }"
          >
            <v-list-tile-content>
              <v-list-tile-title>Groups</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile
            v-if="isAdmin"
            :to="{ name: 'admin-settings-users-index' }"
          >
            <v-list-tile-content>
              <v-list-tile-title>Users</v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action v-if="waitingUsers">
              <v-chip
                color="red"
                text-color="white"
                small
              >
                {{ waitingUsers }}
              </v-chip>
            </v-list-tile-action>
          </v-list-tile>
          <v-list-tile :to="{ name: 'admin-settings-values-index' }">
            <v-list-tile-content>
              <v-list-tile-title>Global values</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list-group>
      </v-list>

      <router-view
        name="adminbody"
        flex
      />
    </div>
  </div>
</template>

<script>
import Auth from "@/plugins/auth";
import GlobalMixin from "@/mixins/global";
import BadgesMixin from "@/mixins/badges";

export default {
  mixins: [GlobalMixin, BadgesMixin],
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.route = to.name;
    });
  },
  data() {
    return {
      isAdmin: false,
      route: null
    };
  },
  mounted() {
    this.loadUser();
  },
  methods: {
    loadUser() {
      if (Auth.user.is_admin) {
        this.isAdmin = true;
      }
    }
  }
};
</script>
