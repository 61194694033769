var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ma-3",attrs:{"layout":"column"}},[_c('div',{staticClass:"title"},[_vm._v(" Administration ")]),_c('div',{staticClass:"mt-3",attrs:{"layout":"row"}},[_c('v-list',{staticStyle:{"width":"300px","background":"transparent","border-right":"1px solid #ddd"}},[_c('v-list-group',{attrs:{"no-action":"","prepend-icon":"mdi-clipboard-text","value":[
            'admin-sessions-centers-index',
            'admin-sessions-centers-edit',
            'admin-sessions-topics-index',
            'admin-sessions-topics-edit',
            'admin-sessions-targets-index',
            'admin-sessions-topics-show',
            'admin-sessions-topics-show-info',
            'admin-sessions-topics-show-timeslots',
            'admin-sessions-topics-show-incompatibilities',
            'admin-sessions-topics-show-availabilities',
            'admin-sessions-targets-edit',
            'admin-sessions-settings-index',
            'admin-sessions-settings-edit',
            'admin-sessions-activities-index',
            'admin-sessions-activities-edit',
            'admin-sessions-seasons-index',
            'admin-sessions-seasons-edit',
            'admin-sessions-teams-index',
            'admin-sessions-teams-edit',
            'admin-sessions-packages-index',
            'admin-sessions-packages-edit',
            'admin-sessions-groupstypes-index',
            'admin-sessions-groupstypes-edit'
          ].includes(_vm.route)}},[_c('v-list-tile',{attrs:{"slot":"activator"},slot:"activator"},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("Sessions")])],1)],1),_c('v-list-tile',{attrs:{"to":{ name: 'admin-sessions-topics-index' }}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("Concepts")])],1)],1),_c('v-list-tile',{attrs:{"to":{ name: 'admin-sessions-groupstypes-index' }}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("Types of groups")])],1)],1),_c('v-list-tile',{attrs:{"to":{ name: 'admin-sessions-targets-index' }}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("Target groups")])],1)],1),_c('v-list-tile',{attrs:{"to":{ name: 'admin-sessions-settings-index' }}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("Events settings")])],1)],1),_c('v-list-tile',{attrs:{"to":{ name: 'admin-sessions-activities-index' }}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("Events activities")])],1)],1),(_vm.isFreelances)?_c('v-list-tile',{attrs:{"to":{ name: 'admin-sessions-centers-index' }}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("Centers")])],1)],1):_vm._e(),(_vm.isFreelances)?_c('v-list-tile',{attrs:{"to":{ name: 'admin-sessions-teams-index' }}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("Teams")])],1)],1):_vm._e(),(_vm.isFreelances)?_c('v-list-tile',{attrs:{"to":{ name: 'admin-sessions-seasons-index' }}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("Seasons")])],1)],1):_vm._e(),(_vm.isFreelances)?_c('v-list-tile',{attrs:{"to":{ name: 'admin-sessions-packages-index' }}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("Packages")])],1)],1):_vm._e()],1),_c('v-list-group',{attrs:{"no-action":"","prepend-icon":"mdi-clipboard-account","value":[
            'admin-addressbook-schooltypes-index',
            'admin-addressbook-schooltypes-edit',
            'admin-addressbook-functions-index',
            'admin-addressbook-functions-edit'
          ].includes(_vm.route)}},[_c('v-list-tile',{attrs:{"slot":"activator"},slot:"activator"},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("Address book")])],1)],1),_c('v-list-tile',{attrs:{"to":{ name: 'admin-addressbook-schooltypes-index' }}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("School types")])],1)],1),_c('v-list-tile',{attrs:{"to":{ name: 'admin-addressbook-functions-index' }}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("Functions of contacts")])],1)],1)],1),_c('v-list-group',{attrs:{"no-action":"","prepend-icon":"mdi-calendar","value":[
            'admin-planning-periodtypes-index',
            'admin-planning-periodtypes-edit',
            'admin-planning-periods-index',
            'admin-planning-periods-edit'
          ].includes(_vm.route)}},[_c('v-list-tile',{attrs:{"slot":"activator"},slot:"activator"},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("Planning")])],1)],1),_c('v-list-tile',{attrs:{"to":{ name: 'admin-planning-periodtypes-index' }}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("Period types")])],1)],1),_c('v-list-tile',{attrs:{"to":{ name: 'admin-planning-periods-index' }}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("Periods")])],1)],1)],1),_c('v-list-group',{attrs:{"no-action":"","prepend-icon":"mdi-form-dropdown","value":[
            'admin-forms-index',
            'admin-forms-edit',
            'admin-forms-fields-index',
            'admin-forms-bstypes-index',
            'admin-forms-bstypes-edit'
          ].includes(_vm.route)}},[_c('v-list-tile',{attrs:{"slot":"activator"},slot:"activator"},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("Forms")])],1)],1),_c('v-list-tile',{attrs:{"to":{ name: 'admin-forms-index' }}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("Forms' list")])],1)],1),(_vm.isBeesecure)?_c('v-list-tile',{attrs:{"to":{ name: 'admin-forms-bstypes-index' }}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("BEE SECURE's forms")])],1)],1):_vm._e()],1),_c('v-list-group',{attrs:{"no-action":"","prepend-icon":"mdi-cog-box","value":[
            'admin-settings-groups-index',
            'admin-settings-groups-edit',
            'admin-settings-users-index',
            'admin-settings-users-list',
            'admin-settings-users-profiles-matrix',
            'admin-settings-users-contracts',
            'admin-settings-users-show',
            'admin-settings-users-show-info',
            'admin-settings-users-show-historic',
            'admin-settings-users-show-contracts',
            'admin-settings-users-show-skills',
            'admin-settings-users-show-skills-edit',
            'admin-settings-users-show-skills-seasons',
            'admin-settings-users-edit',
            'admin-settings-values-index'
          ].includes(_vm.route)}},[_c('v-list-tile',{attrs:{"slot":"activator"},slot:"activator"},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("Settings")])],1),(_vm.waitingUsers)?_c('v-list-tile-action',[_c('v-chip',{attrs:{"color":"red","text-color":"white","small":""}},[_vm._v(" "+_vm._s(_vm.waitingUsers)+" ")])],1):_vm._e()],1),(_vm.isAdmin)?_c('v-list-tile',{attrs:{"to":{ name: 'admin-settings-groups-index' }}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("Groups")])],1)],1):_vm._e(),(_vm.isAdmin)?_c('v-list-tile',{attrs:{"to":{ name: 'admin-settings-users-index' }}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("Users")])],1),(_vm.waitingUsers)?_c('v-list-tile-action',[_c('v-chip',{attrs:{"color":"red","text-color":"white","small":""}},[_vm._v(" "+_vm._s(_vm.waitingUsers)+" ")])],1):_vm._e()],1):_vm._e(),_c('v-list-tile',{attrs:{"to":{ name: 'admin-settings-values-index' }}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("Global values")])],1)],1)],1)],1),_c('router-view',{attrs:{"name":"adminbody","flex":""}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }