<template>
  <div>
    <div class="availability-container">
      <div class="dates">
        <date-representation :date="availability.start"/>
        <span class="separator">-</span>
        <date-representation :date="availability.end"/>
        <div class="availability-actions">
          <v-btn
            class="mx-0"
            icon
            @click="startEditingAvailability"
            title="Edit this exception"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <delete-button
            label="this availability"
            title="Remove this availability"
            @confirm="$emit('delete')"
          />
        </div>
      </div>
      <div class="availability-content">
        <em v-if="availability.exceptions.length === 0">The concept <strong>{{topic.name}}</strong> will be available from {{start}} to {{end}} with no exception for the moment</em>
        <div>
          <v-btn
            v-if="!addExceptionEnabled"
            flat
            @click="addException"
          >
            <v-icon
              left
              color="primary"
            >
              mdi-plus-box
            </v-icon>
            Add a new exception
          </v-btn>
          <div class="form-exception" v-if="addExceptionEnabled">
            <v-form
              ref="form"
            >
              <div class="form-head"><h3>{{editedException.id ? 'Edit exception' : 'New exception'}}</h3></div>
              <div>
                <label>How do you want this exception to impact this availability period ?</label>
                <v-radio-group
                  v-model="editedException.is_excluding"
                  row
                >
                  <v-radio
                    label="Restrict to the dates of this exception"
                    :value="false"
                  ></v-radio>
                  <v-radio
                    label="Exclude the dates of this exception"
                    :value="true"
                  ></v-radio>
                </v-radio-group>
              </div>
              <div>
                <label>How do you want to define this exception ?</label>
                <v-radio-group
                  v-model="editedException.type"
                  row
                  @change="cleanOppositeValues"
                >
                  <v-radio
                    label="Date to Date"
                    :value="Consts.TOPIC_EXCEPTIONS.D2D"
                  ></v-radio>
                  <v-radio
                    label="Days of the week"
                    :value="Consts.TOPIC_EXCEPTIONS.WD"
                  ></v-radio>
                </v-radio-group>
              </div>
              <div v-if="editedException.type === Consts.TOPIC_EXCEPTIONS.D2D">
                <div class="d2d">
                  <date-field
                    class="date-field"
                    :form="$refs.form"
                    v-model="editedException.start"
                    label="Start date"
                  />
                  <date-field
                    class="date-field"
                    :form="$refs.form"
                    v-model="editedException.end"
                    label="End date"
                  />
                </div>
              </div>
              <div v-else>
                <div>
                  <label>What days of the week this exception do apply ?</label>
                  <div class="row">
                    <div><v-checkbox v-model="editedException.d1" label="Monday"/></div>
                    <div><v-checkbox v-model="editedException.d2" label="Tuesday"/></div>
                    <div><v-checkbox v-model="editedException.d3" label="Wednesday"/></div>
                    <div><v-checkbox v-model="editedException.d4" label="Thursday"/></div>
                    <div><v-checkbox v-model="editedException.d5" label="Friday"/></div>
                    <div><v-checkbox v-model="editedException.d6" label="Saturday"/></div>
                    <div><v-checkbox v-model="editedException.d7" label="Sunday"/></div>
                  </div>
                </div>
              </div>
              <div class="edit-footer">
                <v-btn
                  depressed
                  color="primary"
                  @click="saveException"
                >
                  {{editedException.id ? 'Update this exception' : 'Create this exception'}}
                </v-btn>
                <span>or</span>
                <a @click="cancelEdition">Cancel</a>
              </div>
            </v-form>
          </div>
          <div v-else class="exceptions-list">
            <div
              v-for="e in availability.exceptions"
              :key="e.id"
              class="exception"
            >
              <v-icon
                v-if="e.is_excluding"
                class="excluding-icon"
                title="Exclude this dates"
              >mdi-cancel</v-icon>
              <v-icon
                v-else
                class="excluding-icon"
                title="Restrict to this dates"
              >mdi-arrow-collapse</v-icon>
              <div class="detail">
                <span v-if="e.is_excluding">Not during</span>
                <span v-else>Only during</span>
                <span v-if="e.type == Consts.TOPIC_EXCEPTIONS.D2D">&nbsp;the period from the <strong v-smart-date="e.start"/> to the <strong v-smart-date="e.end"/></span>
                <span v-else>&nbsp;the following day(s) : <strong v-days="e"/></span>
              </div>
              <div class="actions">
                <v-btn
                  class="mx-0"
                  icon
                  @click="editException(e)"
                  title="Edit this exception"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <delete-button
                  label="this exception"
                  title="Remove this exception"
                  @confirm="deleteException(e)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-edit-availability" v-if="isAvailabilityEditing">
        <div class="form-head mb30"><h3>Edit current availability</h3></div>
        <div class="form">
          <div class="d2d">
            <date-field
              class="date-field"
              :form="$refs.form"
              v-model="editedAvailability.start"
              label="Start date"
            />
            <date-field
              class="date-field"
              :form="$refs.form"
              v-model="editedAvailability.end"
              label="End date"
            />
          </div>
          <div class="modal-actions">
            <v-btn
              depressed
              color="primary"
              @click="$emit('update', editedAvailability); isAvailabilityEditing = false"
            >
              Update availability
            </v-btn>
            <span>or</span>
            <a @click="isAvailabilityEditing = false">Cancel</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Params from "@/api/params"
  import DateRepresentation from './DateRepresentation.vue'
  import Consts from '@/consts.js'
  import DateField from '@/components/shared/DateField.vue'
  import DeleteButton from "@/components/shared/DeleteButton.vue"

  const exceptionModel = {
    id: null,
    is_excluding: false,
    type: Consts.TOPIC_EXCEPTIONS.D2D,
    start: null,
    end: null,
    d1: false,
    d2: false,
    d3: false,
    d4: false,
    d5: false,
    d6: false,
    d7: false
  };

  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

  export default {
    components: {DateRepresentation, DateField, DeleteButton},
    props: {
      availability: {
        type: Object,
        required: true
      },
      topic: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        editedException: structuredClone(exceptionModel),
        addExceptionEnabled: false,
        isAvailabilityEditing: false,
        editedAvailability: {id: null, start: null, end: null},
        Consts
      }
    },
    methods: {
      addException() {
        this.editedException = Object.assign(this.editedException, structuredClone(exceptionModel))
        this.addExceptionEnabled = true
      },
      cleanOppositeValues() {
        if(this.editedException.type === Consts.TOPIC_EXCEPTIONS.D2D) {
          for(let i = 1; i<= 7; i++) {
            this.editedException[`d${i}`] = false;
          }
        }
        else {
          this.editedException.start = null
          this.editedException.end = null
        }
      },
      async saveException() {
        try {
          if( ! this.editedException.id) {
            const {data} = await Params.create(`topics/${this.topic.id}/availabilities/${this.availability.id}/exceptions`, this.editedException)
              this.availability.exceptions.push(data)
          }
          else {
            const {data} = await Params.update(`topics/${this.topic.id}/availabilities/${this.availability.id}/exceptions`, this.editedException.id, this.editedException)
              this.availability.exceptions.splice(this.availability.exceptions.indexOf(this.availability.exceptions.find(e => e.id == this.editedException.id)), 1, data)
          }
          this.addExceptionEnabled = false
          this.$snotify.success(
            `The exception has been successfully saved`
          )
        }
        catch(e) {
          console.log(e)
        }
      },
      editException(ex) {
        this.editedException = Object.assign(this.editedException, ex)
        this.editedException.is_excluding = !!ex.is_excluding
        this.addExceptionEnabled = true
      },
      cancelEdition() {
        this.addExceptionEnabled = false
      },
      async deleteException(ex) {
        try {
          await Params.delete(`topics/${this.topic.id}/availabilities/${this.availability.id}/exceptions`, ex.id)
          this.availability.exceptions.splice(this.availability.exceptions.indexOf(ex), 1)
          this.$snotify.success(
            `The exception has been successfully deleted`
          )
        }
        catch(e) {
          console.log(e)
        }
      },
      startEditingAvailability() {
        const {id, start, end} = this.availability
        this.editedAvailability = Object.assign(this.editedAvailability,  {id, start, end})
        this.isAvailabilityEditing = true
      }
    },
    computed: {
      start() {
        return this.availability.start.split('-').reverse().join('.')
      },
      end() {
        return this.availability.end.split('-').reverse().join('.')
      },
    },
    directives: {
      smartDate(el, binding) {
        el.innerText = binding.value.split('-').reverse().join('.')
      },
      days(el, binding) {
        const selectedDays = []
        for(let i = 1; i <= 7 ; i++) {
          if(binding.value[`d${i}`]) {
            selectedDays.push(days[i-1])
          }
        }

        el.innerText = selectedDays.join(', ')
      }
    }
  }
</script>
<style scoped lang="sass">
  .availability-container
    display: flex
    flex-direction: row
    align-items: start
    padding: 20px
    border-bottom: 1px solid #D1D1D1
    position: relative

  .dates
    display: flex
    flex-direction: column
    align-items: center
    padding: 0px 40px
    border-right: 1px solid #D1D1D1

    .separator
      font-size: 2em
      font-weight: bold

  .availability-content
    width: 100%
    padding: 0px 20px
    flex-grow: 1

  .form-exception
    margin-top: 20px

    h3
      font-size: 1.3em
      margin-bottom: 10px

    label
      font-size: 1.1em
      font-weight: bold

    .row
      display: flex
      flex-direction: row
      align-items: center

      > *
        margin-right: 20px
  .d2d
    display: flex
    flex-direction: row
    align-items: center

    .date-field
      width: 220px !important
      flex-grow: 0
      margin-right: 10px


  .exception
    display: flex
    flex-direction: row
    align-items: center
    height: 50px
    font-size: 1.2em

    &:nth-child(odd)
      background-color: white

    .excluding-icon
      margin-right: 15px
      margin-left: 5px
      flex-shrink: 1

    .detail
      flex-grow: 1

  .edit-footer
    a
      text-decoration: underline
    > *
      margin-right: 10px

  .availability-actions
    display: inline-flex

  .modal-edit-availability
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(230, 230, 230, 0.97)
    padding: 20px

    .modal-actions
      a
        text-decoration: underline
      > *
        margin-right: 10px

  .mb30
    margin-bottom: 30px

</style>
