<template>
  <v-dialog
    v-model="isVisible"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        color="primary"
        fixed
      >
        <v-btn
          icon
          @click="cancel"
        >
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="org.id">
          Edit the organization
        </v-toolbar-title>
        <v-toolbar-title v-else>
          New organization
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          flat
          :disabled="loading"
          @click="save"
        >
          Save the organization
        </v-btn>
      </v-toolbar>

      <v-card-text
        layout="row"
        layout-align="center center"
        class="mt-5 pt-5"
      >
        <v-form
          ref="orgForm"
          class="field-width"
        >
          <template v-if="info">
            <div class="title font-weight-light mb-4">
              Organization - erliewen.snj.lu
            </div>
            <div
              class="pa-2 mb-4 erliewen-block nl2br"
              v-text="info"
            />
          </template>
          <div class="title font-weight-light mb-4">
            Details of the organization
          </div>

          <v-text-field
            v-model="org.name"
            outline
            label="Name *"
            :rules="[RULES.Required]"
          />

          <v-radio-group
            v-model="org.is_school"
            row
            mandatory
            class="mt-0"
          >
            <v-radio
              :value="1"
              label="This is a school"
            />
            <v-radio
              :value="0"
              label="This is an institution"
            />
          </v-radio-group>

          <v-select
            v-if="org.is_school"
            v-model="org.school_type_id"
            v-focus
            outline
            label="Type of institution *"
            :items="schoolTypes"
            item-value="id"
            item-text="name"
            :rules="[RULES.Required]"
          />

          <v-text-field
            v-model="org.client_no"
            outline
            label="Client number"
          />

          <div class="title font-weight-light mb-4">
            Address
          </div>

          <div layout="row">
            <v-text-field
              v-model="org.number"
              outline
              label="Number"
              class="mr-1"
            />
            <v-text-field
              v-model="org.street"
              outline
              label="Street"
              class="ml-1"
            />
          </div>

          <div layout="row">
            <v-text-field
              v-model="org.zip_code"
              outline
              label="ZIP Code"
              class="mr-1"
            />
            <v-text-field
              v-model="org.city"
              outline
              label="City"
              class="ml-1"
            />
          </div>

          <div layout="row">
            <v-text-field
              v-model="org.phone"
              outline
              label="Phone number"
              class="mr-1"
            />
            <v-text-field
              v-model="org.fax"
              outline
              label="Fax number"
              class="ml-1"
            />
          </div>

          <v-text-field
            v-model="org.email"
            outline
            label="E-mail address"
          />

          <v-textarea
            v-model="org.parking"
            outline
            label="Parking facilities"
          />

          <div class="text-xs-right">
            <v-btn
              depressed
              color="primary"
              :disabled="loading"
              @click="save"
            >
              Save the organization
            </v-btn>
            or
            <a
              href="#"
              class="black--text"
              @click.prevent="cancel"
            >Cancel</a>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
    import Params from '@/api/params'
    import Organizations from '@/api/organizations'
    import RulesMixin from '@/mixins/rules'

    export default {
        mixins: [RulesMixin],
        props: {
            value: {
                type: Object,
                required: false,
                default () { return {is_school: 1} }
            },
            visible: {
                type: Boolean,
                required: false,
                default: false
            },
            info: {
                type: String,
                required: false,
                default: ''
            },
            clientNo: {
                type: String,
                required: false,
                default: ''
            }
        },
        data () {
            return {
                schoolTypes: [],
                loading: false
            }
        },
        computed: {
            org: {
                get () {
                    return this.value;
                },
                set (x) {
                    this.$emit('input', x);
                }
            },
            isVisible: {
                get () {
                    return this.visible;
                },
                set (x) {
                    this.$emit('update:visible', x);
                }
            }
        },
        watch: {
            isVisible(val){
                if(val){
                    this.fetchParams();
                }
            }
        },
        mounted () {
            //this.fetchParams()
            if(this.clientNo){
                this.org.client_no = this.clientNo
            }
        },
        methods: {
            fetchParams () {
                Params.getList('schools-types', {is_enabled: 1, sortBy: 'name', current_id: this.org.school_type_id ? this.org.school_type_id : null}).then(res => {
                    this.schoolTypes = res.data;
                })
            },
            cancel () {
                this.isVisible = false
            },
            save () {
                if (!this.$refs['orgForm'].validate()) {
                    this.$snotify.error('Please check the form for invalid values');
                    return;
                }

                this.loading = true;

                if (!this.org.id) {
                    Organizations.create(this.org).then(res => {
                        this.isVisible = false;
                        this.loading = false;
                        this.$emit('created', res.data);
                    }, () => {
                        this.loading = false;
                    })
                } else {
                    Organizations.update(this.org.id, this.org).then(res => {
                        this.isVisible = false;
                        this.loading = false;
                        this.$emit('updated', res.data);
                    }, () => {
                        this.loading = false;
                    })
                }
            }
        }
    }
</script>
<style scoped>
.erliewen-block{
  background-color: #EEEEEE;
}
</style>
