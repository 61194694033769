<template>
  <div class="ma-3">
    <div class="title mb-3">
      Sessions <v-icon>mdi-chevron-right</v-icon> Concepts management
    </div>

    <div
      layout="column"
      layout-align="center center"
    >
      <div
        layout="column"
        class="field-width"
      >
        <div
          layout="row"
          layout-align="start center"
          class="ml-3"
        >
          <v-select
            v-model="filters.application"
            v-focus
            solo
            single-line
            hide-details
            label="Application"
            :items="applications"
            class="mr-2"
            @change="fetch"
          />
          <v-text-field
            v-model="filters.keywords"
            solo
            label="Keywords"
            hide-details
            @keyup.enter="fetch"
          />
          <v-btn
            depressed
            color="primary"
            @click="fetch"
          >
            Filter
          </v-btn>
          or
          <a
            href="#"
            class="ml-2 black--text"
            @click="resetFilters"
          >Cancel</a>
        </div>

        <div>
          <v-btn
            flat
            class="mt-3"
            :to="{name: 'admin-sessions-topics-edit', params: {id: 'new'}}"
          >
            <v-icon
              left
              color="primary"
            >
              mdi-plus-box
            </v-icon>
            Add a new concept
          </v-btn>
        </div>

        <v-data-table
          :headers="headers"
          :items="topics"
          style="max-width: 1000px"
          :pagination.sync="pagination"
        >
          <template
            slot="items"
            slot-scope="{ item }"
          >
            <td align="center">
              <v-icon
                :color="item.is_enabled ? 'success' : 'error'"
                :title="item.is_enabled ? 'Enabled' : 'Disabled'"
              >
                mdi-circle-outline
              </v-icon>
            </td>
            <td align="center">
              <v-icon
                v-if="item.color"
                :color="item.color"
              >
                mdi-circle
              </v-icon>
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.sessions === undefined ? 'N/A' : item.sessions }}</td>
            <td layout="row">
              <v-btn
                class="mx-0"
                icon
                :to="{name: 'admin-sessions-topics-show', params: {id: item.id}}"
                :disabled="!isAppCoord(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                :title="item.is_enabled ? 'Disable': 'Enable'"
                class="mx-0"
                icon
                :disabled="!isAppCoord(item)"
                @click="toggle(item)"
              >
                <v-icon>{{ item.is_enabled ? 'mdi-lock': 'mdi-lock-open' }}</v-icon>
              </v-btn>
              <delete-button
                :label="item.name"
                :disabled="!!item.sessions || !isAppCoord(item)"
                @confirm="deleteImpl(item)"
              />
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
    import Params from '@/api/params'
    import DeleteButton from "@/components/shared/DeleteButton.vue"
    import GlobalMixin from "@/mixins/global"

    export default {
        components: {DeleteButton},
        mixins: [GlobalMixin],
        data () {
            return {
                pagination: {
                    rowsPerPage: 10
                },
                headers: [
                    { text: 'Status', value: 'is_enabled', sortable: false, width: 75, align: 'center' },
                    { text: 'Color', value: 'color', sortable: false, width: 75, align: 'center' },
                    { text: 'Concept', value: 'name' },
                    { text: 'Sessions', value : 'sessions', width: "150px" },
                    { text: 'Actions', sortable: false, width: "80px" }
                ],
                filters: {
                    keywords: null,
                    application: null
                },
                topics: []
            }
        },
        mounted () {
            this.filters.application = this.getStoredApp()
            this.fetch();
        },
        methods: {
            fetch () {
                Params.getList('topics', this.filters).then(res => {
                    this.topics = res.data;
                    this.setStoredApp(this.filters.application)
                })
            },
            resetFilters () {
                this.filters = {keywords: null, application: this.getDefaultApp()};
                this.fetch();
            },
            deleteImpl (item) {
                Params.delete('topics', item.id).then(() => {
                    this.fetch();
                    this.$snotify.success(`The concept "${item.name}" has been deleted`)
                })
            },
            toggle (item) {
                if (item.is_enabled) {
                    Params.disable('topics', item.id).then(() => {
                        this.fetch();
                        this.$snotify.success(`The concept "${item.name}" has been disabled`)
                    })
                } else {
                    Params.enable('topics', item.id).then(() => {
                        this.fetch();
                        this.$snotify.success(`The concept "${item.name}" has been enabled`)
                    })
                }
            }
        }
    }
</script>
