<template>
  <div class="ma-3">
    <div class="title mb-3">
      Sessions
      <v-icon>mdi-chevron-right</v-icon>
      Concepts management
      <v-icon>mdi-chevron-right</v-icon>
      {{ isNew ? 'New' : topic.name }}
    </div>

    <v-form
      ref="form"
      v-model="formValid"
      layout="column"
      layout-align="center center"
      @submit.prevent="save"
    >
      <div>
        <div class="title font-weight-light mb-3">
          Concept information
        </div>
        <v-text-field
          v-model="topic.name"
          outline
          label="Name"
          class="field-width"
          :rules="[ v => !!v || 'This field is required' ]"
        />
        <v-select
          v-if="Auth.user.application == Consts.APPLICATION.ALL"
          v-model="topic.application"
          v-focus
          label="Application"
          :items="apps"
          class="field-width"
          outline
          :rules="[ v => !!v || 'This field is required' ]"
        />
        <v-select
          v-model="topic.teams_ids"
          label="Teams"
          :items="teams"
          item-text="name"
          item-value="id"
          class="field-width"
          @change="onTeamsChange"
          multiple
          outline
          :rules="[ v => v.length > 0 || 'This field is required' ]"
        />
       <v-select
          v-if="topic.teams_ids && topic.teams_ids.length > 1"
          v-model="topic.main_team_id"
          label="Main team"
          :items="topicTeams"
          item-text="name"
          item-value="id"
          class="field-width"
          outline
          :rules="[ v => !!v || 'This field is required' ]"
        />
        <v-text-field
          v-if="topic.application === Consts.APPLICATION.BEESECURE"
          v-model="topic.slug"
          outline
          label="Slug (for sync)"
          class="field-width"
        />
        <!-- Si application == FREELANCES-->
        <template v-if="topic.application === Consts.APPLICATION.FREELANCES">
          <!-- Gestion des formulaires | mécanique commune aux deux applications-->
          <v-menu
            :close-on-content-click="false"
            offset-y
            input-activator
            max-width="225"
          >
            <v-text-field
              slot="activator"
              v-model="color.hex"
              outline
              label="Color"
              class="field-width"
              readonly
              :rules="[ v => !!v || 'This field is required' ]"
            >
              <div slot="prepend-inner">
                <v-icon :color="color.hex">
                  mdi-circle
                </v-icon>
              </div>
            </v-text-field>
            <color-picker v-model="color" />
          </v-menu>
          <div class="title font-weight-light mb-3">
            Target groups
          </div>
          <div id="target-groups-panel">
            <target-selector
              v-for="g in groups"
              :key="g.id"
              :group="g"
              :groupstypes="groupstypes"
              :reserved-ids="reservedGroupstypesIds"
              @cancel="cancelGroupEdition(g)"
              @delete="removeGroup(g)"
              @start-editing="g.is_editing = true"
              @stop-editing="g.is_editing = false"
              @save="(data) => storeGroup(g, data)"
            />
            <div id="tgp-addzone">
              <v-btn
                v-if="!addGroupEnabled"
                flat
                @click="addNewGroup"
              >
                <v-icon
                  left
                  color="primary"
                >
                  mdi-plus-box
                </v-icon>
                Add a new group
              </v-btn>
            </div>
          </div>
          <div class="title font-weight-light mb-3">
            Data for erliewen.snj.lu
          </div>
          <!-- Image et preview -->
          <div>
            <div id="picture-bloc">
              <div id="picture-container"><img v-if="topic.picture" :src="picturePath" /></div>
              <div id="picture-tools">
                <v-text-field
                  v-model="picture.name"
                  label="Picture"
                  prepend-icon="mdi-attachment"
                  @click="pickFile()"
                />
                <v-btn
                  :disabled="!topic.picture_id"
                  flat
                  outline
                  color="primary"
                  title="Download"
                  @click="download(topic)"
                >
                  <v-icon>mdi-download</v-icon>
                  Download current picture
                </v-btn>
              </div>
            </div>
            <input
              ref="filePicture"
              type="file"
              style="display: none"
              accept="image/png, image/jpeg"
              @change="onFilePicked($event)"
            >
          </div>

          <div id="my-editor">
            <label>Description</label>
            <vue-editor
              v-model="topic.description"
              :editorToolbar="customToolbar"
            ></vue-editor>
          </div>
          <div>
            <v-checkbox
              v-model="topic.is_pmr"
              label="Accessible for people with reduced mobility"
              :true-value="1"
              :false-value="0"
            />
          </div>
          <div>
            <v-text-field
              v-model="topic.price"
              outline
              label="Price of the activity (leave 0 if free)"
              class="field-width"
              @blur="formatPrice"
            />
          </div>
          <div class="flex-line">
            <v-text-field
              v-model="topic.min_participants"
              outline
              label="Minimum number of participants"
              class="field-width"
            />
            <v-text-field
              v-model="topic.max_participants"
              outline
              label="Maximum number of participants"
              class="field-width"
            />
          </div>
          <div>
            <v-textarea
              v-model="topic.alternative_location"
              outline
              class="field-width"
              label="Alternative location (in case different from the center of the main team)"
            />
          </div>

          <div class="title font-weight-light mb-3">
            Reporting
          </div>
          <v-autocomplete
            v-model="topic.daggesrapport_training_id"
            label="Daggesrapport form for trainings"
            :items="forms"
            item-text="name"
            item-value="id"
            class="field-width"
            outline
            clearable
            :open-on-clear="false"
            @click:clear="clearFormField('daggesrapport_training_id')"
          />
          <v-autocomplete
            v-model="topic.daggesrapport_activity_id"
            label="Daggesrapport form for activities"
            :items="forms"
            item-text="name"
            item-value="id"
            class="field-width"
            outline
            clearable
            :open-on-clear="false"
            @click:clear="clearFormField('daggesrapport_activity_id')"
          />
          <v-autocomplete
            v-model="topic.evaluation_training_id"
            label="Individual evaluation form for trainings"
            :items="forms"
            item-text="name"
            item-value="id"
            class="field-width"
            outline
            clearable
            :open-on-clear="false"
            @click:clear="clearFormField('evaluation_training_id')"
          />
          <v-autocomplete
            v-model="topic.evaluation_activity_id"
            label="Individual evaluation form fo activities"
            :items="forms"
            item-text="name"
            item-value="id"
            class="field-width"
            outline
            clearable
            :open-on-clear="false"
            @click:clear="clearFormField('evaluation_activity_id')"
          />
        </template>
      </div>

      <v-btn
        depressed
        color="primary"
        @click="save"
      >
        Save
      </v-btn>
      <router-link
        :to="{name: 'admin-sessions-topics-index'}"
        class="grey--text"
      >
        Cancel
      </router-link>
    </v-form>
  </div>
</template>

<script>
import Params from "@/api/params"
import GlobalMixin from "@/mixins/global"
import FormDataMixin from "@/mixins/formdata"
import Auth from "@/plugins/auth"
import Consts from "@/consts"
import axios from 'axios'
import config from "@/config.js"
import { VueEditor } from "vue2-editor";
import TargetSelector from './TargetSelector'

export default {
  components: {VueEditor, TargetSelector},
  mixins: [GlobalMixin, FormDataMixin],
  props: {
    value: {
      type: Object,
    }
  },
  data() {
    return {
      customToolbar: [
        [{header: [3, 4, 5, false]}],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"]
      ],
      topic: {
        name: null,
        price: 0,
        is_pmr: false,
        application: Auth.user.application,
        teams_ids: [],
        main_team_id: null,
        morning_start: null,
        morning_end: null,
        afternoon_start: null,
        afternoon_end: null,
        evening_start: null,
        evening_end: null,
        day_start: null,
        day_end: null,
        morning_participants_start: null,
        morning_participants_end: null,
        afternoon_participants_start: null,
        afternoon_participants_end: null,
        evening_participants_start: null,
        evening_participants_end: null,
        day_participants_start: null,
        day_participants_end: null,
        morning_total_time: "0,00",
        afternoon_total_time: "0,00",
        evening_total_time: "0,00",
        day_total_time: "0,00",
        description: "",
        color: { hex: "#000000" },
        daggesrapport_training_id: null,
        daggesrapport_activity_id: null,
        evaluation_training_id: null,
        evaluation_activity_id: null
      },
      formValid: false,
      teams: [],
      Consts,
      Auth,
      forms: [],
      picture: {name: '', data: null},
      color: {hex: ''},
      groups: [],
      groupstypes: [], // liste de tous les types de groupes dans les PG (enabled)
      addGroupEnabled: false
    }
  },
  computed: {
    isNew() {
      return this.$route.params.id === "new" && this.value === undefined;
    },
    picturePath() {
      const path = this.topic.picture.path ?? null
      if(path) {
        const min_name = path.substring(0, path.indexOf('.'+this.topic.picture.extension))
        return `${config.AssetsUrl}/${config.PicturePath}/${min_name}_min.jpg`
      }
      return ""
    },
    topicTeams() {
      return this.teams.filter(t => this.topic.teams_ids.indexOf(t.id) >= 0)
    },
    reservedGroupstypesIds() {
      return this.groups.map(t => t.id)
    }
  },
  mounted() {
    Params.getList("teams", { sortBy: 'name', is_enabled: 1 }).then(res => {
      this.teams = res.data;
    });
    Params.getList('forms', {sortBy: 'name'}).then(res => {
        this.forms = res.data;
    })
    Params.getList('groups-types', {sortBy: 'name'}).then(res => {
        this.groupstypes = res.data;
    })

    if (!this.isNew) {
      this.fetch();
    }
  },
  methods: {
    addNewGroup() {
      this.addGroupEnabled = true
      this.groups.push({id: null, is_full: true, targets: [], is_editing: true})
    },
    cancelAddingGroup() {
      this.addGroupEnabled = false
      this.addingGroup.id = null
      this.addingGroup.is_full = true
      this.tgp_to_select.splice(0)
      this.tgp_to_unselect.splice(0)
      this.addingGroup.target_groups.splice(0) //on vide tout
    },
    onTeamsChange() {
      if(this.topic.teams_ids.length === 0) {
        this.topic.main_team_id = null
      }
      else if(this.topic.teams_ids.length === 1) {
        this.topic.main_team_id = this.topic.teams_ids[0]
      }
    },
    clearFormField(f) {
      this.$nextTick(() => {
        this.topic[f] = null
      })
    },
    fetch() {
      if(this.value !== undefined) {
        this.topic = Object.assign(this.topic, structuredClone(this.value))
        this.prepareTopic()
      }
      else {
        Params.get("topics", this.$route.params.id).then(res => {
          this.topic = Object.assign(this.topic, res.data);
          this.prepareTopic()
        });
      }
    },
    prepareTopic () {
      this.color.hex = this.topic.color;
      this.topic.teams_ids = [];
      if (this.topic && this.topic.teams && this.topic.teams.length > 0) {
        for (let i = 0; i < this.topic.teams.length; i++) {
          this.topic.teams_ids.push(this.topic.teams[i].id);
        }
      }
      this.groups.splice(0) //on vide tout proprement
      if(this.topic.topic_groupstypes.length > 0) {
        this.topic.topic_groupstypes.forEach(gt => {
          let targets = []
          if(gt.targets_groups && gt.targets_groups.length > 0) {
            targets = gt.targets_groups.map(target => {return {id: target.targets_group.id, name: target.targets_group.name}})
          }
          this.groups.push({
            id: gt.groupstype_id,
            is_full: !!gt.is_full,
            targets,
            is_editing: false //pour bloquer le save si c'est en cours d'édition
          })
        })
      }
      this.topic.price = isNaN(parseFloat(this.topic.price)) ? "0,00" : new Intl.NumberFormat("fr-FR").format(parseFloat(this.topic.price))
    },
    formatPrice() {
      const p = this.topic.price.replace(' ', '').replace(',', '.');
      this.topic.price = isNaN(parseFloat(p)) ? "0,00" : new Intl.NumberFormat("fr-FR").format(parseFloat(p))
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.color.hex) {
        this.topic.color = this.color.hex;
      } else {
        this.topic.color = null
      }

      let formData = new FormData();
      if (this.picture.data && this.picture.name) {
          formData.append("picture_id", this.picture.data, this.picture.name);
      }

      //on teste qu'aucun groupe n'est en cours d'édition
      for(let i = 0 ; i < this.groups.length ; i++) {
        const g = this.groups[i]
        if(g.is_editing) {
          this.$snotify.error(
            `At least one group is still being edited, please cancel or validate it before saving the concept`
          )
          return;
        }
      }

      let copy = structuredClone(this.topic)
      copy.groups = structuredClone(this.groups)
      copy.groups = copy.groups.map(cg => {
        cg.targets = cg.targets.map(cgt => cgt.id)
        return cg
      })
      copy.handle_groups = true; //c'est pour indiquer au BE que l'on doit traiter les target groups
      this._convertObjectToFormData(formData, copy);

      if (this.isNew) {
        Params.create("topics", formData, true).then((res) => {
          this.$router.push({ name: "admin-sessions-topics-show", params: {id: res.data.id} });
          this.$snotify.success(
            `The concept "${this.topic.name}" has been created`
          );
          copy = null
        });
      } else {
        Params.update("topics", this.topic.id, formData, true).then((res) => {
          //on est déjà dans le formulaire, par contre faut donner au père le nouvel objet
          this.$emit("topic:update", res.data)
          this.$snotify.success(
            `The concept "${this.topic.name}" has been updated`
          );
          copy = null
        });
      }
    },
    pickFile() {
      this.$refs['filePicture'].click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture.data = files[0];
        this.picture.name = files[0].name;
      }
    },
    download (topic) {
      let a = document.createElement("a");
      a.style = "display: none";
      document.body.appendChild(a);

      axios.get(`/params/topics/${topic.id}/picture`, {responseType: 'blob'}).then(res => {
        let blob = new Blob([res.data], {type: "octet/stream"});

        if (window.navigator && window.navigator.msSaveOrOpenBlob){
          window.navigator.msSaveBlob(blob, topic.picture.name); //il faudra aller chercher le vrai nom du fichier
        }
        else
        {
          let url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = topic.picture.name;
          a.click();
          window.URL.revokeObjectURL(url);
        }
      });
    },
    cancelGroupEdition(group) {
      this.groups.splice(this.groups.indexOf(group), 1)
      this.addGroupEnabled = false
    },
    removeGroup(group) {
      this.groups.splice(this.groups.indexOf(group), 1)
    },
    storeGroup(group, newValue) {
      console.log(newValue)
      this.groups.splice(this.groups.indexOf(group), 1, newValue)
      if(this.addGroupEnabled) {
        this.addGroupEnabled = false
      }
    }
  }
};
</script>


<style scoped lang="sass">
  #picture-bloc
    display: flex
    flex-direction: row
    align-items: top
    margin-bottom: 20px

    #picture-container
      display: flex
      flex-direction: row
      justify-content: center
      align-items: center
      background-color: #F1F1F1
      width: 150px
      height: 150px
      border-radius: 5px
      margin-right: 10px

      img
        width: auto
        height: auto

    #picture-tools
      flex-grow: 1

  #my-editor
    margin-bottom: 20px

    label
      font-size: 1.2em

  .flex-line
    display: flex
    flex-direction: row
    width: 100%

    > *
      width: auto
      flex-shrink: 1

</style>
