<template>
	<div class="date-representation">
		<div class="up">{{day}}.{{month}}</div>
		<div class="down">{{year}}</div>
	</div>
</template>
<script>
	const months = {
		"01": "JAN",
		"02": "FEB",
		"03": "MAR",
		"04": "APR",
		"05": "MAY",
		"06": "JUN",
		"07": "JUL",
		"08": "AUG",
		"09": "SEP",
		"10": "OCT",
		"11": "NOV",
		"12": "DEC",
	}

	export default {
		props: ["date"],
		computed: {
			day() {
				return this.date.split('-')[2]
			},
			month() {
				return months[this.date.split('-')[1]]
			},
			year() {
				return this.date.split('-')[0]
			}
		}
	}
</script>
<style scoped lang="sass">
	.date-representation
		display: flex
		flex-direction: column
		align-items: center

		.up
			font-size: 1.5em
			color: #555555
			padding: 0
			margin: 0
		.down
			font-weight: bold
			font-size: 2.2em
			color: #f4b944
			padding: 0
			margin: 0
</style>
