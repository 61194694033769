<template>
  <div class="ma-3">
    <div class="title mb-3">
      Sessions
      <v-icon>mdi-chevron-right</v-icon>
      Concepts management
      <v-icon>mdi-chevron-right</v-icon>
      {{ topic.name }}
      <v-icon>mdi-chevron-right</v-icon>
      Availabilities
    </div>
    <div>
      <div class="info-block">
        <v-icon size="40px" color="#4b8e9a" class="info-icon">mdi-information-variant-box</v-icon>
        By default, if no availability period is defined on the topic for a reservation date, the topic will be considered as unavailable.
      </div>
      <div class="form">
        <date-field
          class="date-field"
          v-model="period.start"
          label="Start date"
        />
        <date-field
          class="date-field"
          v-model="period.end"
          label="End date"
        />
        <v-btn
          depressed
          color="primary"
          @click="addPeriod"
        >
          Add new period
        </v-btn>
      </div>
      <availability
        v-for="a in availabilities"
        :availability="a"
        :topic="topic"
        :key="a.id"
        @delete="deleteAvailability(a)"
        @update="(editedAvailability) => updateAvailability(a, editedAvailability)"
      />
    </div>
  </div>
</template>

<script>
  import Params from "@/api/params"
  import GlobalMixin from "@/mixins/global"
  import Availability from './Availability.vue'
  import DateField from '@/components/shared/DateField.vue'

  export default {
    components: {Availability, DateField},
    mixins: [GlobalMixin],
    props: {
      value: {
        type: Object,
      }
    },
    data() {
      return {
        topic: {},
        availabilities: [],
        period: {start: null, end: null}
      };
    },
    mounted() {
      this.topic = structuredClone(this.value)
      this.fetch()
    },
    methods: {
      async fetch() {
        const {data} = await Params.getList(`topics/${this.topic.id}/availabilities`, {sortBy: 'start', descending: true})
        this.availabilities = data
      },
      async addPeriod() {
        try {
          const {data} = await Params.create(`topics/${this.topic.id}/availabilities`, this.period)
          this.availabilities.unshift(data)
          this.$snotify.success(
            `The availabilty period has been created with success`
          )
        }
        catch(e) {
          console.log(e)
        }
      },
      async deleteAvailability(availability) {
        try {
         await Params.delete(`topics/${this.topic.id}/availabilities`, availability.id)
          this.availabilities.splice(this.availabilities.indexOf(availability), 1)
          this.$snotify.success(
            `The availabilty period has been deleted with success`
          )
        }
        catch(e) {
          console.log(e)
        }
      },
      async updateAvailability(availability, newAvailability) {
        try {
         const {data} = await Params.update(`topics/${this.topic.id}/availabilities`, availability.id, newAvailability)
          this.availabilities.splice(this.availabilities.indexOf(availability), 1, data)
          this.$snotify.success(
            `The availabilty period has been updated with success`
          )
        }
        catch(e) {
          console.log(e)
        }
      }
    }
  }
</script>
<style scoped lang="sass">
  .info-block
    padding: 20px
    margin-top: 20px
    display: flex
    flex-direction: row
    align-items: center
    background-color: #ccf7ff
    color: #4b8e9a

    .info-icon
      margin-right: 20px

  .form
    display: flex
    flex-direction: row
    align-items: center
    justify-content: start
    margin-top: 20px
    background-color: #F1F1F1
    padding-top: 30px
    padding-left: 30px

    .date-field
      width: 220px !important
      flex-grow: 0
      margin-right: 10px

    button
      align-self: flex-start
      margin-top: 10px
</style>
