import Vue from 'vue'
import './plugins/vuetify'
import './plugins/snotify'
import './plugins/colorizer'
import './plugins/charts'
import './plugins/dayspan'

import router from './plugins/router'
import Pragtify from 'pragtify'
import App from './App.vue'

import './styles/flexbox.css'
import './styles/app.scss'
import '@mdi/font/css/materialdesignicons.min.css'
import "vue-snotify/styles/material.css"


Vue.config.productionTip = false

Vue.directive('focus', {
    inserted: function (el, binding, vnode) {
        let sel = vnode.componentInstance.$refs.input
        sel.addEventListener('focus', () => {
            vnode.componentInstance.isMenuActive = true
        }, true)
    }
})

Vue.use(Pragtify)

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
