<template>
  <topic-form :value="value" @topic:update="(t) => { $emit('topic:update', t) }" />
</template>

<script>
import TopicForm from "../Edit.vue"

export default {
  components: { TopicForm },
  props: {
		value: {
			type: Object,
			required: true,
			default: () => {}
		}
	}
}
</script>
