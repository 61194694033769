var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ma-3"},[_c('div',{staticClass:"title mb-3"},[_vm._v(" Sessions "),_c('v-icon',[_vm._v("mdi-chevron-right")]),_vm._v(" Events packages management "),_c('v-icon',[_vm._v("mdi-chevron-right")]),_vm._v(" "+_vm._s(_vm.isNew ? 'New' : _vm.pack.name)+" ")],1),_c('v-form',{ref:"form",attrs:{"layout":"column","layout-align":"center center"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('div',[_c('div',{staticClass:"title font-weight-light mb-3"},[_vm._v(" Package information ")]),_c('v-text-field',{staticClass:"field-width",attrs:{"outline":"","label":"Name","rules":[ v => !!v || 'This field is required' ]},model:{value:(_vm.pack.name),callback:function ($$v) {_vm.$set(_vm.pack, "name", $$v)},expression:"pack.name"}}),_c('v-text-field',{staticClass:"field-width",attrs:{"outline":"","label":"Code","rules":[ v => !!v || 'This field is required' ]},model:{value:(_vm.pack.code),callback:function ($$v) {_vm.$set(_vm.pack, "code", $$v)},expression:"pack.code"}}),_c('v-text-field',{staticClass:"field-width",attrs:{"outline":"","lazy":"","label":"Price of the package (leave 0 if free)"},on:{"blur":_vm.formatPrice},model:{value:(_vm.pack.price),callback:function ($$v) {_vm.$set(_vm.pack, "price", $$v)},expression:"pack.price"}}),_c('v-text-field',{staticClass:"field-width",attrs:{"type":"number","outline":"","label":"Number of days","rules":[
          v => /^[0-9]+$/.test(v) || 'This field must be a number',
          v => (!!v && v > 0) || 'This field is required'
        ]},on:{"input":_vm.changeNbDay},model:{value:(_vm.numDay),callback:function ($$v) {_vm.numDay=$$v},expression:"numDay"}}),_c('div',{staticClass:"title font-weight-light mb-3"},[_vm._v(" Package days ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.pack.days,"hide-actions":""},scopedSlots:_vm._u([{key:"items",fn:function({ item }){return [_c('td',[_vm._v(_vm._s(item.day))]),_c('td',{staticStyle:{"width":"415px"}},[_c('v-select',{staticClass:"mt-2",attrs:{"items":_vm.topics,"item-text":"name","item-value":"id","label":"Concept","outline":"","hide-details":""},on:{"change":function($event){return _vm.updateConceptHours(item, 'morning')}},model:{value:(item.morning_topic_id),callback:function ($$v) {_vm.$set(item, "morning_topic_id", $$v)},expression:"item.morning_topic_id"}}),_c('div',{staticClass:"d-inline-flex"},[_c('v-text-field',{staticClass:"mt-2",attrs:{"outline":"","hide-details":"","label":"Start (work)","placeholder":"HH:MM","rules":[
                  v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                  v => !item.morning_end || !v || parseInt(item.morning_end.replace(':','')) >= parseInt(v.replace(':','')) || 'End time must be greater than start time',
                ]},on:{"change":function($event){return _vm.updateDuration(item, 'morning')}},model:{value:(item.morning_start),callback:function ($$v) {_vm.$set(item, "morning_start", $$v)},expression:"item.morning_start"}},[_c('div',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_c('v-menu',{ref:'morningStartMenu'+item.day,attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":item.morning_start,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(item, "morning_start", $event)},"update:return-value":function($event){return _vm.$set(item, "morning_start", $event)}}},[_c('v-icon',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v(" access_time ")]),_c('v-time-picker',{attrs:{"format":"24hr"},on:{"click:minute":function($event){_vm.$refs['morningStartMenu'+item.day].save(item.morning_start); item.clock_start_text = item.morning_start; _vm.updateDuration(item, 'morning')}},model:{value:(item.morning_start),callback:function ($$v) {_vm.$set(item, "morning_start", $$v)},expression:"item.morning_start"}})],1)],1)]),_c('v-text-field',{staticClass:"mt-2 mb-2",attrs:{"outline":"","hide-details":"","label":"End (work)","placeholder":"HH:MM","rules":[
                  v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                  v => !item.morning_start || !v || parseInt(v.replace(':','')) >= parseInt(item.morning_start.replace(':','')) || 'End time must be greater than start time',
                ]},on:{"change":function($event){return _vm.updateDuration(item, 'morning')}},model:{value:(item.morning_end),callback:function ($$v) {_vm.$set(item, "morning_end", $$v)},expression:"item.morning_end"}},[_c('div',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_c('v-menu',{ref:'morningEndMenu'+item.day,attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":item.morning_end,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(item, "morning_end", $event)},"update:return-value":function($event){return _vm.$set(item, "morning_end", $event)}}},[_c('v-icon',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v(" access_time ")]),_c('v-time-picker',{attrs:{"format":"24hr"},on:{"change":function($event){_vm.$refs['morningEndMenu'+item.day].save(item.morning_end); item.clock_start_text = item.morning_end; _vm.updateDuration(item, 'morning')}},model:{value:(item.morning_end),callback:function ($$v) {_vm.$set(item, "morning_end", $$v)},expression:"item.morning_end"}})],1)],1)])],1),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{attrs:{"label":"Work duration (decimals hours)","hide-details":""},model:{value:(item.morning_total_time),callback:function ($$v) {_vm.$set(item, "morning_total_time", $$v)},expression:"item.morning_total_time"}})],1),_c('div',{staticClass:"d-inline-flex"},[_c('v-text-field',{staticClass:"mt-2",attrs:{"outline":"","hide-details":"","label":"Start (participants)","placeholder":"HH:MM","rules":[
                  v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                  v => !item.morning_participants_end || !v || parseInt(item.morning_participants_end.replace(':','')) >= parseInt(v.replace(':','')) || 'End time must be greater than start time',
                ]},model:{value:(item.morning_participants_start),callback:function ($$v) {_vm.$set(item, "morning_participants_start", $$v)},expression:"item.morning_participants_start"}},[_c('div',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_c('v-menu',{ref:'morningParticipantsStartMenu'+item.day,attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":item.morning_participants_start,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(item, "morning_participants_start", $event)},"update:return-value":function($event){return _vm.$set(item, "morning_participants_start", $event)}}},[_c('v-icon',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v(" access_time ")]),_c('v-time-picker',{attrs:{"format":"24hr"},on:{"change":function($event){_vm.$refs['morningParticipantsStartMenu'+item.day].save(item.morning_participants_start); item.clock_start_text = item.morning_participants_start}},model:{value:(item.morning_participants_start),callback:function ($$v) {_vm.$set(item, "morning_participants_start", $$v)},expression:"item.morning_participants_start"}})],1)],1)]),_c('v-text-field',{staticClass:"mt-2 mb-2",attrs:{"outline":"","hide-details":"","label":"End (participants)","placeholder":"HH:MM","rules":[
                  v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                  v => !item.morning_participants_start || !v || parseInt(v.replace(':','')) >= parseInt(item.morning_participants_start.replace(':','')) || 'End time must be greater than start time',
                ]},model:{value:(item.morning_participants_end),callback:function ($$v) {_vm.$set(item, "morning_participants_end", $$v)},expression:"item.morning_participants_end"}},[_c('div',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_c('v-menu',{ref:'morningParticipantsEndMenu'+item.day,attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":item.morning_participants_end,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(item, "morning_participants_end", $event)},"update:return-value":function($event){return _vm.$set(item, "morning_participants_end", $event)}}},[_c('v-icon',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v(" access_time ")]),_c('v-time-picker',{attrs:{"format":"24hr"},on:{"change":function($event){_vm.$refs['morningParticipantsEndMenu'+item.day].save(item.morning_participants_end); item.clock_start_text = item.morning_participants_end}},model:{value:(item.morning_participants_end),callback:function ($$v) {_vm.$set(item, "morning_participants_end", $$v)},expression:"item.morning_participants_end"}})],1)],1)])],1)],1),_c('td',{staticStyle:{"width":"415px"}},[_c('v-select',{staticClass:"mt-2",attrs:{"items":_vm.topics,"item-text":"name","item-value":"id","label":"Concept","outline":"","hide-details":""},on:{"change":function($event){return _vm.updateConceptHours(item, 'afternoon')}},model:{value:(item.afternoon_topic_id),callback:function ($$v) {_vm.$set(item, "afternoon_topic_id", $$v)},expression:"item.afternoon_topic_id"}}),_c('div',{staticClass:"d-inline-flex"},[_c('v-text-field',{staticClass:"mt-2",attrs:{"outline":"","label":"Start (work)","placeholder":"HH:MM","hide-details":"","rules":[
                  v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                  v => !item.afternoon_end || !v || parseInt(item.afternoon_end.replace(':','')) >= parseInt(v.replace(':','')) || 'End time must be greater than start time',
                ]},on:{"change":function($event){return _vm.updateDuration(item, 'afternoon')}},model:{value:(item.afternoon_start),callback:function ($$v) {_vm.$set(item, "afternoon_start", $$v)},expression:"item.afternoon_start"}},[_c('div',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_c('v-menu',{ref:'afternoonStartMenu'+item.day,attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":item.afternoon_start,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(item, "afternoon_start", $event)},"update:return-value":function($event){return _vm.$set(item, "afternoon_start", $event)}}},[_c('v-icon',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v(" access_time ")]),_c('v-time-picker',{attrs:{"format":"24hr"},on:{"change":function($event){_vm.$refs['afternoonStartMenu'+item.day].save(item.afternoon_start); item.clock_start_text = item.afternoon_start; _vm.updateDuration(item, 'afternoon')}},model:{value:(item.afternoon_start),callback:function ($$v) {_vm.$set(item, "afternoon_start", $$v)},expression:"item.afternoon_start"}})],1)],1)]),_c('v-text-field',{staticClass:"mt-2 mb-2",attrs:{"outline":"","label":"End (work)","placeholder":"HH:MM","hide-details":"","rules":[
                  v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                  v => !item.afternoon_start || !v || parseInt(v.replace(':','')) >= parseInt(item.afternoon_start.replace(':','')) || 'End time must be greater than start time',
                ]},on:{"change":function($event){return _vm.updateDuration(item, 'afternoon')}},model:{value:(item.afternoon_end),callback:function ($$v) {_vm.$set(item, "afternoon_end", $$v)},expression:"item.afternoon_end"}},[_c('div',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_c('v-menu',{ref:'afternoonEndMenu'+item.day,attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":item.afternoon_end,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(item, "afternoon_end", $event)},"update:return-value":function($event){return _vm.$set(item, "afternoon_end", $event)}}},[_c('v-icon',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v(" access_time ")]),_c('v-time-picker',{attrs:{"format":"24hr"},on:{"change":function($event){_vm.$refs['afternoonEndMenu'+item.day].save(item.afternoon_end); item.clock_start_text = item.afternoon_end; _vm.updateDuration(item, 'afternoon')}},model:{value:(item.afternoon_end),callback:function ($$v) {_vm.$set(item, "afternoon_end", $$v)},expression:"item.afternoon_end"}})],1)],1)])],1),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{attrs:{"label":"Work duration (decimals hours)","hide-details":""},model:{value:(item.afternoon_total_time),callback:function ($$v) {_vm.$set(item, "afternoon_total_time", $$v)},expression:"item.afternoon_total_time"}})],1),_c('div',{staticClass:"d-inline-flex"},[_c('v-text-field',{staticClass:"mt-2",attrs:{"outline":"","label":"Start (participants)","placeholder":"HH:MM","hide-details":"","rules":[
                  v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                  v => !item.afternoon_participants_end || !v || parseInt(item.afternoon_participants_end.replace(':','')) >= parseInt(v.replace(':','')) || 'End time must be greater than start time',
                ]},model:{value:(item.afternoon_participants_start),callback:function ($$v) {_vm.$set(item, "afternoon_participants_start", $$v)},expression:"item.afternoon_participants_start"}},[_c('div',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_c('v-menu',{ref:'afternoonStartParticipantsMenu'+item.day,attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":item.afternoon_participants_start,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(item, "afternoon_participants_start", $event)},"update:return-value":function($event){return _vm.$set(item, "afternoon_participants_start", $event)}}},[_c('v-icon',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v(" access_time ")]),_c('v-time-picker',{attrs:{"format":"24hr"},on:{"change":function($event){_vm.$refs['afternoonStartParticipantsMenu'+item.day].save(item.afternoon_participants_start); item.clock_start_text = item.afternoon_participants_start}},model:{value:(item.afternoon_participants_start),callback:function ($$v) {_vm.$set(item, "afternoon_participants_start", $$v)},expression:"item.afternoon_participants_start"}})],1)],1)]),_c('v-text-field',{staticClass:"mt-2 mb-2",attrs:{"outline":"","label":"End (participants)","placeholder":"HH:MM","hide-details":"","rules":[
                  v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                  v => !item.afternoon_participants_start || !v || parseInt(v.replace(':','')) >= parseInt(item.afternoon_participants_start.replace(':','')) || 'End time must be greater than start time',
                ]},model:{value:(item.afternoon_participants_end),callback:function ($$v) {_vm.$set(item, "afternoon_participants_end", $$v)},expression:"item.afternoon_participants_end"}},[_c('div',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_c('v-menu',{ref:'afternoonParticipantsEndMenu'+item.day,attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":item.afternoon_participants_end,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(item, "afternoon_participants_end", $event)},"update:return-value":function($event){return _vm.$set(item, "afternoon_participants_end", $event)}}},[_c('v-icon',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v(" access_time ")]),_c('v-time-picker',{attrs:{"format":"24hr"},on:{"change":function($event){_vm.$refs['afternoonParticipantsEndMenu'+item.day].save(item.afternoon_participants_end); item.clock_start_text = item.afternoon_participants_end}},model:{value:(item.afternoon_participants_end),callback:function ($$v) {_vm.$set(item, "afternoon_participants_end", $$v)},expression:"item.afternoon_participants_end"}})],1)],1)])],1)],1)]}}])})],1),_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.save}},[_vm._v(" Save ")]),_c('router-link',{staticClass:"grey--text",attrs:{"to":{name: 'admin-sessions-packages-index'}}},[_vm._v(" Cancel ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }