<template>
  <div class="ma-3">
    <div class="title mb-3">
      Sessions <v-icon>mdi-chevron-right</v-icon> Types of groups management
    </div>

    <div
      layout="column"
      layout-align="center center"
    >
      <div
        layout="column"
        class="field-width"
      >
        <div
          layout="row"
          layout-align="start center"
          class="ml-3"
        >
          <v-select
            v-model="filters.type"
            v-focus
            solo
            single-line
            hide-details
            label="Type"
            :items="types"
            class="mr-2"
            @change="fetch"
          />
          <v-text-field
            v-model="filters.keywords"
            solo
            label="Keywords"
            hide-details
            @keyup.enter="fetch"
          />
          <v-btn
            depressed
            color="primary"
            @click="fetch"
          >
            Filter
          </v-btn>
          or
          <a
            href="#"
            class="ml-2 black--text"
            @click="resetFilters"
          >Cancel</a>
        </div>

        <div>
          <v-btn
            flat
            class="mt-3"
            :to="{name: 'admin-sessions-groupstypes-edit', params: {id: 'new'}}"
          >
            <v-icon
              left
              color="primary"
            >
              mdi-plus-box
            </v-icon>
            Add a new type of groups
          </v-btn>
        </div>

        <v-data-table
          :headers="headers"
          :items="groupstypes"
          style="max-width: 1000px"
          :pagination.sync="pagination"
        >
          <template
            slot="items"
            slot-scope="{ item }"
          >
            <td align="center">
              <v-icon
                :color="item.is_enabled ? 'success' : 'error'"
                :title="item.is_enabled ? 'Enabled' : 'Disabled'"
              >
                mdi-circle-outline
              </v-icon>
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.type == Consts.GROUPS_TYPES.CYCLE ? 'Cycles' : 'Ages' }}</td>
            <td layout="row">
              <v-btn
                class="mx-0"
                icon
                :to="{name: 'admin-sessions-groupstypes-edit', params: {id: item.id}}"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                :title="item.is_enabled ? 'Disable': 'Enable'"
                class="mx-0"
                icon
                @click="toggle(item)"
              >
                <v-icon>{{ item.is_enabled ? 'mdi-lock': 'mdi-lock-open' }}</v-icon>
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import Params from '@/api/params'
import SessionMixin from '@/mixins/sessions'
import Consts from '@/consts'

export default {
  mixins: [SessionMixin],
  data () {
    return {
      Consts,
      types: [
        { value: Consts.GROUPS_TYPES.CYCLE, text: "Cycles" },
        { value: Consts.GROUPS_TYPES.AGES, text: "Ages" }
      ],
      pagination: {
        rowsPerPage: 10
      },
      headers: [
        { text: 'Status', value: 'is_enabled', sortable: false, width: 75, align: 'center' },
        { text: 'Type of groups', value: 'name' },
        { text: 'Type', value: 'type' },
        { text: 'Actions', sortable: false, width: "80px" }
      ],
      filters: {
        keywords: null,
        type: null
      },
      groupstypes: []
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      Params.getList('groups-types', this.filters).then(res => {
        this.groupstypes = res.data
      })
    },
    resetFilters () {
      this.filters = { keywords: null, type: null }
      this.fetch()
    },
    toggle (item) {
      if (item.is_enabled) {
        Params.disable('groups-types', item.id).then(() => {
          this.fetch();
          this.$snotify.success(`The type of groups "${item.name}" has been disabled`)
        })
      } else {
        Params.enable('groups-types', item.id).then(() => {
          this.fetch();
          this.$snotify.success(`The type of groups "${item.name}" has been enabled`)
        })
      }
    }
  }
}
</script>
