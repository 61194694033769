<template>
  <div class="ma-3">
    <div class="title mb-3">
      Sessions
      <v-icon>mdi-chevron-right</v-icon>
      Types of group management
      <v-icon>mdi-chevron-right</v-icon>
      {{ isNew ? 'New' : groupstype.name }}
    </div>

    <v-form
      ref="form"
      v-model="formValid"
      layout="column"
      layout-align="center center"
      @submit.prevent="save"
    >
      <div>
        <div class="title font-weight-light mb-3">
          Type of groups information
        </div>
        <v-text-field
          v-model="groupstype.name"
          outline
          label="Name"
          class="field-width"
          :rules="[ v => !!v || 'This field is required' ]"
        />
        <v-select
          v-model="groupstype.type"
          v-focus
          label="Type"
          :items="types"
          class="field-width"
          outline
          :rules="[ v => !!v || 'This field is required' ]"
        />
      </div>

      <v-btn
        depressed
        color="primary"
        @click="save"
      >
        Save
      </v-btn>
      <router-link
        :to="{name: 'admin-sessions-groupstypes-index'}"
        class="grey--text"
      >
        Cancel
      </router-link>
    </v-form>
  </div>
</template>

<script>
import Params from "@/api/params";
import GlobalMixin from "@/mixins/global";
import Consts from "@/consts";

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      groupstype: {
        name: null,
        type: Consts.GROUPS_TYPES.CYCLE,
      },
      formValid: false,
      Consts,
      types: [
        { value: Consts.GROUPS_TYPES.CYCLE, text: "Cycles" },
        { value: Consts.GROUPS_TYPES.AGES, text: "Ages" }
      ]
    };
  },
  computed: {
    isNew() {
      return this.$route.params.id === "new";
    }
  },
  mounted() {
    if (!this.isNew) {
      this.fetch();
    }
  },
  methods: {
    fetch() {
      Params.get("groups-types", this.$route.params.id).then(res => {
        this.groupstype = res.data;
      })
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.isNew) {
        Params.create("groups-types", this.groupstype).then(() => {
          this.$router.push({ name: "admin-sessions-groupstypes-index" });
          this.$snotify.success(
            `The type of groups "${this.groupstype.name}" has been created`
          );
        });
      } else {
        Params.update("groups-types", this.groupstype.id, this.groupstype).then(() => {
          this.$router.push({ name: "admin-sessions-groupstypes-index" });
          this.$snotify.success(
            `The type of groups "${this.groupstype.name}" has been updated`
          );
        });
      }
    }
  }
};
</script>
