<template>
  <div class="ma-3">
    <div class="title mb-3">
      Sessions
      <v-icon>mdi-chevron-right</v-icon>
      Centers management
      <v-icon>mdi-chevron-right</v-icon>
      {{ isNew ? 'New' : center.name }}
    </div>
     <div v-if="center.slug" class="legend">
      <v-icon>mdi-star-four-points-small</v-icon><span>{{center.slug}}</span></div>

    <v-form
      ref="form"
      v-model="formValid"
      layout="column"
      layout-align="center center"
      @submit.prevent="save"
    >
      <div>
        <div class="title font-weight-light mb-3">
          Center information
        </div>
        <v-text-field
          v-model="center.name"
          outline
          label="Name"
          class="field-width"
          :rules="[ v => !!v || 'This field is required' ]"
        />
        <v-text-field
          v-model="center.email"
          outline
          label="Email"
          class="field-width"
          :rules="[ v => !!v || 'This field is required' ]"
        />
        <v-checkbox
          v-model="center.is_pmr"
          label="Accessible for people with reduced mobility"
          :true-value="1"
          :false-value="0"
        />
        <div>
            <v-text-field
              v-model="template.name"
              label="Email template"
              prepend-icon="mdi-attachment"
              @click="pickFile()"
            />
            <v-btn
              :disabled="!center.template_doc_id"
              flat
              outline
              color="primary"
              title="Download"
              @click="download(center.id)"
            >
              <v-icon>mdi-download</v-icon>
              Download current template
            </v-btn>
            <input
              ref="fileTemplate"
              type="file"
              style="display: none"
              accept="text/html"
              @change="onFilePicked($event)"
            >
          </div>
      </div>

      <v-btn
        depressed
        color="primary"
        @click="save"
      >
        Save
      </v-btn>
      <router-link
        :to="{name: 'admin-sessions-centers-index'}"
        class="grey--text"
      >
        Cancel
      </router-link>
    </v-form>
  </div>
</template>

<script>
import Params from "@/api/params"
import GlobalMixin from "@/mixins/global"
import FormDataMixin from "@/mixins/formdata"
import Auth from "@/plugins/auth"
import Consts from "@/consts"
import axios from 'axios'

export default {
  mixins: [GlobalMixin, FormDataMixin],
  data() {
    return {
      center: {
        name: null,
        email: null,
        is_pmr: false,
        id: null,
        slug: ""
      },
      template: {
        data: null,
        name: ""
      },
      formValid: false,
      Consts,
      Auth
    };
  },
  computed: {
    isNew() {
      return this.$route.params.id === "new";
    }
  },
  mounted() {
    if (!this.isNew) {
      this.fetch();
    }
  },
  methods: {
    fetch() {
      Params.get("centers", this.$route.params.id).then(res => {
        this.center = res.data;
      });
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let formData = new FormData();
      if (this.template.data && this.template.name) {
          formData.append("template_doc_id", this.template.data, this.template.name);
      }

      this._convertObjectToFormData(formData, this.center);

      if (this.isNew) {
        Params.create("centers", formData, true).then(() => {
          this.$router.push({ name: "admin-sessions-centers-index" });
          this.$snotify.success(
            `The center "${this.center.name}" has been created`
          );
        });
      } else {
        Params.update("centers", this.center.id, formData, true).then(() => {
          this.$router.push({ name: "admin-sessions-centers-index" });
          this.$snotify.success(
            `The center "${this.center.name}" has been updated`
          );
        });
      }
    },
    pickFile() {
        this.$refs['fileTemplate'].click();
    },
    onFilePicked(e) {
        const files = e.target.files;
        if (files[0] !== undefined) {
          this.template.data = files[0];
          this.template.name = files[0].name;
        }
    },
    download (id) {
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";

        axios.get(`/params/centers/${id}/template`, {responseType: 'blob'}).then(res => {
            let blob = new Blob([res.data], {type: "octet/stream"});

            if (window.navigator && window.navigator.msSaveOrOpenBlob){
                window.navigator.msSaveBlob(blob, "template_" + id + ".html");
            }
            else
            {
                let url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = "template_" + id + ".html";
                a.click();
                window.URL.revokeObjectURL(url);
            }
        });
    }
  }
};
</script>

<style scoped lang="sass">
  .legend
    color: #A1A1A1
    display: flex
    flex-direction: row
    align-items: center
</style>
