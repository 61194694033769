<template>
  <v-text-field
    ref="field"
    v-model="text"
    :label="label"
    outline
    :hide-details="hideDetails"
    :rules="rules"
    @blur="update"
    @change="change"
  >
    <div slot="prepend">
      <v-menu
        ref="search"
        :close-on-content-click="false"
        :return-value.sync="computedValue"
        transition="scale-transition"
        offset-y
        full-width
        lazy
      >
        <v-icon slot="activator">
          event
        </v-icon>
        <v-date-picker
          v-model="computedValue"
          first-day-of-week="1"
        />
      </v-menu>
    </div>
  </v-text-field>
</template>
<script>
import moment from 'moment'

export default {
	props: {
		value: {
			type: String,
			required: false,
			default: ''
		},
		label: {
			type: String,
			required: false,
			default: ''
		},
		hideDetails: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			required: false,
			default: false
		},
		form: {
			type: Object,
			required: false,
			default: null
		}
	},
	data() {
		return {
			text: '',
			initied: false
		}
	},
	computed: {
		computedValue: {
      get() {
        return this.value
      },
      set(x) {
        this.$emit("input", x)
				this.$emit("change", x)
				this.$refs.search.save(x)
				this.text = this.americanizeDate(x)
      }
    },
		rules () {
			return [
        v => (!this.required || !!v) || 'This field is required',
        v => (v && moment(v, 'DD.MM.YYYY').isValid()) || 'Invalid time'
      ]
		}
	},
	watch: {
		value(v){
			if(!this.initied && v && v.length === 10 && !this.text){
				this.initied = true
				this.text = this.americanizeDate(this.value)
			}
		}
	},
	mounted() {
		setTimeout(() => {
			if(this.form){
				this.form.register(this.$refs.field)
			}
		}, 500)
		if(this.value) {
			this.text = this.americanizeDate(this.value)
		}
	},
	methods: {
		change(v){
			this.$emit('change', v)
		},
		update(){
			this.computedValue = this.parseDate(this.text)
		},
		americanizeDate(date) {
      if (!date) return null;

      if (date.indexOf(".") >= 0) {
        return date;
      }

      return moment(date, "YYYY-MM-DD").format("DD.MM.YYYY");
    },
    parseDate(date) {
      if (!date) return null;

      if (date.indexOf("-") >= 0) {
        return date;
      }

      return moment(date, "DD.MM.YYYY").format("YYYY-MM-DD");
    },
	},
}
</script>
